<template>
    <div class="container">
      <h1 style="margin:20px auto;text-align:center;font-size:24px;">赢回来后台管理系统</h1>
      <div class="tel-card login-card">
          <el-form ref="form" :model="form">
            <el-form-item>
              <el-input v-model="form.mobile" prefix-icon="icomoon icon-user-check" placeholder="注册手机号码"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input type="password" v-model="form.password" prefix-icon="icomoon icon-key" placeholder="密码"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="login-btn" type="primary" @click="onSubmit" :disabled="!form.password || !isTel">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
    </div>
</template>
<script>
import {accountLogin} from '../api/login.js'
export default{
  data(){
    return {
        isTel:false,
        isPassword:false,
        form:{
            mobile:'',
            password:''
        },
    };
    },
    computed:{
    },
    // 监听输入的手机号码格式是否正确
    watch:{
        'form.mobile':function(newVal,oldVal){
            var reg=/^1[3456789]\d{9}$/;
            if(reg.test(newVal)){
                this.isTel=true;
            }else{
                this.isTel=false;
            }
        }
    },
    methods: {
        onSubmit() {
           accountLogin(this.form).then(res=>{
              let data = res.data;
              if(data.code=='000'){
                this.$message.success('登录成功');
                this.$store.commit('GET_ACCOUNT_PROFILE',data.content);
                this.$store.dispatch('getAllArea');
                this.$router.push('/');
              }else{
                  this.$message({
                      type:'warning',
                      message:data.desc
                  });
              }
           })

        }
    },
    created(){
    }
}
</script>
<style scoped>
.container{
    width: 400px;
    height: 400px;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    margin:auto;
}
.el-tabs__nav.is-top{
    margin-left:96px;
}
.login-card{
    border:1px solid #ddd;
    padding:24px;
}

.login-btn{
    width: 100%;
}
.width{
    width: 160px;

}
.img-code{
    cursor: pointer;
    height: 40px;
    margin-left: 30px;
}
</style>
